
// app/javascript/controllers/login_controller.js
import { Controller } from '@hotwired/stimulus'
import { Toast, Popover, Tooltip } from 'bootstrap'
// import { DarkEditable } from './dark-editable';
import { Dropzone } from "dropzone";
// import heic2any from "heic2any";

// import { Clipboard } from 'clipboard'

// Connects to data-controller='login'
export default class extends Controller {

  connect() {

    $('#clock_icon').on('click', function() {
      $('#clocks').slideToggle();
    });


    // set up copy to clipboard functionality
    var clipboard = new ClipboardJS('.clipbtn');

    clipboard.on('success', function(e) {
      // console.info('Action:', e.action);
      // console.info('Text:', e.text);
      // console.info('Trigger:', e.trigger);
      e.trigger.innerHTML = ' Copied!';
      e.clearSelection();
    });

    $('#message_template_id').on('change', function () {
      const val = this.value;
      console.log('selected <' + val + '>');
      $.ajax({
        url: '/admin/message_template/' + val,
        type: 'GET',
        error: function () {
          alert('Sorry, something went wrong getching the message template #' + val);
        },
        success: function (res) {
          $('#clipboard_hidden_content').val(res.text);
          console.log('clipboard hidden content was set to text for template '+val+', which is '+res.text);
        }
      });
    });

    setTimeout(function() {
      var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        console.log('adding popover to ' + popoverTriggerEl.id);
        return new Popover(popoverTriggerEl)
      })

      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        // console.log('adding tooltip to ' + tooltipTriggerEl.id);
        return new Tooltip(tooltipTriggerEl)
      })
    },1000);

    $('.dropzone').each(function() {
      const elid = $(this).attr('id');
      console.log('adding dropzone for '+elid);
        const dropzone = new Dropzone('form#' + elid, {
          url: '/admin/fileupload/'+$(this).attr('id'),
          maxFiles: 1,
          maxFilesize: 15,
          maxThumbnailFilesize: 15,
          acceptedFiles: ".jpg, .jpeg, .png, .heic, .pdf",
          thumbnailWidth: "250",
          thumbnailHeight: "250",
          success: function(file, response){
            //console.log('using thumbnail url : '+response.thumbnail_url);

            this.createThumbnailFromUrl(file, response.thumbnail_url);
            console.log('setting img_'+elid+' to '+response.thumbnail_url+'?v='+Date.now());
            $('#img_'+elid).attr('src',response.thumbnail_url+'?v='+Date.now());
            $('#img_'+elid).show();
            $('#rotate_'+elid).show();
            $('#toast_title').html('Success!');
            $('#toast_subtitle').html('');
            $('#toast_body').html('The photo was successfully uploaded.')
            const toastElement = document.getElementById("liveToast");
            const toast = new Toast(toastElement);
            toast.show();
          },
          init: function() {
            this.on("success", function(file) {
              $('.dz-image').css({"width":"100%", "height":"auto"});
              //let blobURL = URL.createObjectURL(file);
              //convertHeicImage(blobURL, file)
            })
          },
          // remove the thumbnail after we uploaded it
          complete: function(file) {
            this.removeFile(file);
          },
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          }});
    });



    /*$('.fupload').each(function() {
      const dropzone = new Dropzone(this, { url: "/admin/fileupload" });
    }); */

    // highlight all fields we need to add a value to where there currently is none
    $('.inline-editable').each(function() {
      const content = $(this).text().trim();
      if (content.length == 0) {
        $( this ).addClass( "table-warning" );
      }
    });

    // dynamic data entry
    $('#customer_snippets,#customer_trip_table,#customer_trip_arrivals_table,#device_table,#opalcard_table,#shipping_table,#notes_table,#personal_details_table,#tfn1_details_table,#tfn2_details_table,#tfn3_details_table').editableTableWidget();

    $('table td').on('change', function(evt, newValue) {
      // do something with the new cell value
      //console.log('evt is <'+evt+'>')
      const element_id = evt.target.id;
      // console.log('evt is <'+evt+'>');
      console.log('element_id is <'+element_id+'>');
      if (element_id == 'message_template_id')
      {
        return;
      }
      //console.log('element <'+element_id+'> was changed to <'+newValue+'>');
      const val = newValue.trim();
      $.ajax({
        url: '/admin/update_field',
        data: {
          element: element_id,
          value: val
        },
        type: 'POST',
        error: function () {
          alert('Sorry, something went wrong updating this value.');
          callback();
        },
        success: function (res) {
          if (res.error.length) {
            console.log('backend update for ' + element_id + ' with new value <' + newValue + '> unsuccessful: '+res.error);
            $(evt.target).text(res.value);
            $('#toast_title').html('Sorry!');
            $('#toast_subtitle').html('We could not make this change for you.');
            $('#toast_body').html(res.message);
            const toastElement = document.getElementById("liveToast");
            const toast = new Toast(toastElement);
            toast.show();
          }
          else {
            if (element_id.endsWith('_note_new'))
            {
              window.location.reload();
            }
            else {
              if (res.message.length) {
                var subtitle = '';
                if (res.subtitle.length) {
                  subtitle = res.subtitle;
                }
                var title = 'Success!';
                if (res.title.length) {
                  title = res.title;
                }

                $('#toast_title').html(title)
                $('#toast_subtitle').html(subtitle)
                $('#toast_body').html(res.message)
                const toastElement = document.getElementById("liveToast");
                const toast = new Toast(toastElement);
                toast.show();
              }
              //console.log('val.length is '+val.length);
              if (val.length == 0) {
                $(evt.target).addClass("table-warning");
              }
              console.log('backend update for ' + element_id + ' with new value <' + newValue + '> successful.')
            }
            const nowval = $(evt.target).text().trim();
            if (nowval.length > 0) {
              $(evt.target).removeClass("table-warning");
            }
          }
        }
      });
      return true; // accept change
    });
  }

  show() {
    const toastElement = document.getElementById('liveToast')
    const toast = new Toast(toastElement)
    toast.show();
  }
}

